// import { Alert, Button, Col, Menu, Row } from "antd";
import { Button, Col, Row, Layout, Menu, PageHeader } from "antd";
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';

import "antd/dist/antd.css";
import {
  useBalance,
  useContractLoader,
  useContractReader,
  useGasPrice,
  // useOnBlock,
  useUserProviderAndSigner,
} from "eth-hooks";
import { useEventListener } from "eth-hooks/events/useEventListener";
// import { useLookupAddress } from "eth-hooks/dapps/ens";
import { useExchangeEthPrice } from "eth-hooks/dapps/dex";
import React, { useCallback, useEffect, useState } from "react";
// import { Link, Route, Switch, useLocation } from "react-router-dom";
import { Route, Switch, useLocation, Link } from "react-router-dom";
import "./App.scss";
import punkInfo from "./views/punk_info.json";
import {
  Account,
  // Address,
  // Contract,
  // Faucet,
  // GasGauge,
  // Header,
  // Ramp,
  // ThemeSwitch,
  NetworkDisplay,
  // FaucetHint,
  NetworkSwitch,
  // Events
} from "./components";
import { NETWORKS, ALCHEMY_KEY } from "./constants";
import externalContracts from "./contracts/external_contracts";
// contracts
import deployedContracts from "./contracts/hardhat_contracts.json";
import { Transactor, Web3ModalSetup } from "./helpers";
// import { Home, ExampleUI, Hints, Subgraph, Profile } from "./views";
import { Home, Profile } from "./views";
import { useStaticJsonRPC } from "./hooks";

import MessageForm from "./views/MessageForm";

const { ethers } = require("ethers");
window.ethers = ethers;
import $ from "jquery";
/*
    Welcome to 🏗 scaffold-eth !

    Code:
    https://github.com/scaffold-eth/scaffold-eth

    Support:
    https://t.me/joinchat/KByvmRe5wkR-8F_zz6AjpA
    or DM @austingriffith on twitter or telegram

    You should get your own Alchemy.com & Infura.io ID and put it in `constants.js`
    (this is your connection to the main Ethereum network for ENS etc.)


    🌏 EXTERNAL CONTRACTS:
    You can also bring in contract artifacts in `constants.js`
    (and then use the `useExternalContractLoader()` hook!)
*/

/// 📡 What chain are your contracts deployed to?
const initialNetwork = NETWORKS.localhost; // <------- select your target frontend network (localhost, rinkeby, xdai, mainnet)

// 😬 Sorry for all the console logging
const DEBUG = process.env.NODE_ENV === 'development';
const NETWORKCHECK = true;
const USE_BURNER_WALLET = false; // toggle burner wallet feature
const USE_NETWORK_SELECTOR = true;

const web3Modal = Web3ModalSetup();

// 🛰 providers
const providers = [
  "https://eth-mainnet.gateway.pokt.network/v1/lb/611156b4a585a20035148406",
  `https://mainnet.infura.io/v3/${process.env.MAINNET_INFURA_KEY}`,
  "https://rpc.scaffoldeth.io:48544",
];

function App(props) {
  $(window).on("resize", function() {
    document.querySelector(':root').style.setProperty('--window-width', ($(window).width()) + '');
  }).trigger('resize')
  // specify all the chains your app is available on. Eg: ['localhost', 'mainnet', ...otherNetworks ]
  // reference './constants.js' for other networks
  const networkOptions = [initialNetwork.name, "mainnet", "rinkeby"];
  
  const { Header, Footer, Sider, Content } = Layout

  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();
  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[
    process.env.NODE_ENV === 'development' ? 1 : 1
  ]);
  const location = useLocation();

  const targetNetwork = NETWORKS[selectedNetwork];

  // 🔭 block explorer URL
  const blockExplorer = targetNetwork.blockExplorer;
  const openSea = targetNetwork.openSea;
  
  // load all your providers
  const localProvider = useStaticJsonRPC([
    process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : targetNetwork.rpcUrl,
  ]);
  const mainnetProvider = useStaticJsonRPC(providers);
  
  // if (DEBUG) console.log(`Using ${selectedNetwork} network`);
  
  // 🛰 providers
  // if (DEBUG) console.log("📡 Connecting to Mainnet Ethereum");

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  /* 💵 This hook will get the price of ETH from 🦄 Uniswap: */
  const price = useExchangeEthPrice(targetNetwork, mainnetProvider);

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice(targetNetwork, "fast");
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, localProvider, USE_BURNER_WALLET);
  const userSigner = userProviderAndSigner.signer;
  
  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  // For more hooks, check out 🔗eth-hooks at: https://www.npmjs.com/package/eth-hooks

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userSigner, gasPrice);
  window.tx = tx

  // 🏗 scaffold-eth is full of handy hooks like this one to get your balance:
  // const yourLocalBalance = useBalance(localProvider, address);

  // Just plug in different 🛰 providers to get your balance on different chains:
  // const yourMainnetBalance = useBalance(mainnetProvider, address);

  // const contractConfig = useContractConfig();

  const contractConfig = { deployedContracts: deployedContracts || {}, externalContracts: externalContracts || {} };

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider, contractConfig);
  window.readContracts = readContracts;

  // If you want to make 🔐 write transactions to your contracts, use the userSigner:
  const writeContracts = useContractLoader(userSigner, contractConfig, localChainId);
  window.writeContracts = writeContracts;
  
  // window.sendTransaction = async function() {
  //   var provider =  (new ethers.providers.Web3Provider(window.ethereum))
  //   var signer = provider.getSigner()
  //   var data = ethers.utils.toUtf8Bytes('Call Data TEST! 👍')
  //   var address = await readContracts.ForeverMessage.address
    
  //   var result = await signer.sendTransaction({to: address, data: data })
    
  //   // console.log(await signer.call({to: address, data: data}))
    
  //   console.log(result)
  //   console.log(await result.wait())
  // }

  // EXTERNAL CONTRACT EXAMPLE:
  //
  // If you want to bring in the mainnet DAI contract it would look like:
  const mainnetContracts = useContractLoader(mainnetProvider, contractConfig);

  // If you want to call a function on a new block
  // useOnBlock(mainnetProvider, () => {
  //   // console.log(`⛓ A new mainnet block is here: ${mainnetProvider._lastBlockNumber}`);
  // });

  // Then read your DAI balance like:
  /*
  const addressFromENS = useResolveName(mainnetProvider, "austingriffith.eth");
  console.log("🏷 Resolved austingriffith.eth as:",addressFromENS)
  */

  
  const events = useEventListener(readContracts, "FashionHatPunks", "Transfer", localProvider, 1);
  
  
  
  
  
  //
  // 🧫 DEBUG 👨🏻‍🔬
  //
  useEffect(() => {
    if (
      DEBUG &&
      mainnetProvider &&
      address &&
      selectedChainId &&
      // yourLocalBalance &&
      // yourMainnetBalance &&
      readContracts &&
      writeContracts &&
      mainnetContracts
    ) {
      console.log("_____________________________________ 🏗 scaffold-eth _____________________________________");
      console.log("🌎 mainnetProvider", mainnetProvider);
      console.log("🏠 localChainId", localChainId);
      console.log("👩‍💼 selected address:", address);
      console.log("🕵🏻‍♂️ selectedChainId:", selectedChainId);
      // console.log("💵 yourLocalBalance", yourLocalBalance ? ethers.utils.formatEther(yourLocalBalance) : "...");
      // console.log("💵 yourMainnetBalance", yourMainnetBalance ? ethers.utils.formatEther(yourMainnetBalance) : "...");
      console.log("📝 readContracts", readContracts);
      // console.log("🌍 DAI contract on mainnet:", mainnetContracts);
      // console.log("💵 yourMainnetDAIBalance", myMainnetDAIBalance);
      console.log("🔐 writeContracts", writeContracts);
    }
  }, [
    mainnetProvider,
    address,
    selectedChainId,
    // yourLocalBalance,
    // yourMainnetBalance,
    readContracts,
    writeContracts,
    mainnetContracts,
  ]);

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));
    
    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  // const faucetAvailable = localProvider && localProvider.connection && targetNetwork.name.indexOf("local") !== -1;

  return (
    <Layout className="App" style={{position: 'relative', overflow: 'hidden'}}>
              <div style={{
          position: 'absolute',
          // display: 'flex',
          // alignItems: 'center',
          // justifyContent: 'center',
          // flexWrap: 'wrap',
          // transform: 'rotate(-10deg) scale(1)',
          // alignContent: 'flex-start',
          backgroundColor: "#a08a82",
          backgroundImage: "url('https://res.cloudinary.com/middlemarch/image/upload/v1648488330/Fashion%20Hat%20Punks/montage123_small.png')",
          // transformOrigin: 'top',
        top: 0, left: 0, width: "100%", height: "100%"}}>
          {/* <img
          style={{
            position: "absolute",
            left: "50%",
            // top: "50%",
            top: "0%",
            // transform: "translate(-50%, -50%) rotate(15deg)"
            transform: "translate(-50%, -50%)"
          }}
          
          src="https://res.cloudinary.com/middlemarch/image/upload/v1648488330/Fashion%20Hat%20Punks/montage123_small.png"></img> */}
          {/* {
            new Array(250).fill(0).map((_, i) => {
              var url = `https://fashion-hat-phunks.mypinata.cloud/ipfs/QmTd1BaDjARASVvCaRVMLUDXo9GBq73zdHpuSdgsHR9YZ9/${i}.png`
              return <img key={i} src={url} style={{width: '100px', height: '100px'}}/>
            })
          }
          {
            new Array(50).fill(0).map((_, i) => {
              var url = `https://fashion-hat-phunks.mypinata.cloud/ipfs/QmTd1BaDjARASVvCaRVMLUDXo9GBq73zdHpuSdgsHR9YZ9/${i}.png`
              return <img key={i} src={url} style={{width: '100px', height: '100px'}}/>
            })
          } */}
        </div>
      <Header style={{display: 'flex', alignItems: 'center',
      justifyContent: 'space-between', position: 'relative', zIndex: 1, background: 'rgba(0,0,0,.75)', color: "white"}}>
        <div className='center'>
          <h2>
            <Link to="/" style={{ color: 'white', textShadow: 'rgb(0 0 0 / 65%) 1px 1px 5px' }}>
              Fashion Hat Punks
            </Link>
          </h2>
          {/* <h4
            style={{color: 'white', textShadow: 'rgb(0 0 0 / 65%) 1px 1px 5px'}}
          
          >100% No Affiliation Implied!</h4> */}
        </div>
        
        <div className='side'>
          
          <NetworkDisplay
                NETWORKCHECK={NETWORKCHECK}
                localChainId={localChainId}
                selectedChainId={selectedChainId}
                targetNetwork={targetNetwork}
                logoutOfWeb3Modal={logoutOfWeb3Modal}
                USE_NETWORK_SELECTOR={USE_NETWORK_SELECTOR}
              />
              {(USE_NETWORK_SELECTOR &&
                <NetworkSwitch
                  networkOptions={networkOptions}
                  selectedNetwork={selectedNetwork}
                  setSelectedNetwork={setSelectedNetwork}
                />
              )}
              <Account
                useBurner={USE_BURNER_WALLET}
                address={address}
                localProvider={localProvider}
                userSigner={userSigner}
                mainnetProvider={mainnetProvider}
                price={price}
                web3Modal={web3Modal}
                loadWeb3Modal={loadWeb3Modal}
                logoutOfWeb3Modal={logoutOfWeb3Modal}
              />
          
          
        </div>

      </Header>
      
      <Layout className="mainContent" style={{position: 'relative',
      background: 'transparent', overflow: 'hidden', paddingBottom: 100}}>

      <Switch className="switch">
        <Route exact path="/">
          <Home readContracts={readContracts}
            loadWeb3Modal={loadWeb3Modal}
            price={price}
            localProvider={localProvider}
            blockExplorer={blockExplorer}
            openSea={openSea}
            messageForm={
              <MessageForm
                tx={tx}
                resolvedAddress={address}
                ensProvider={mainnetProvider}
                writeContracts={writeContracts}
                price={price}
                readContracts={readContracts}
                loadWeb3Modal={loadWeb3Modal}
                address={address}
                events={events}
                punkInfo={punkInfo}
              ></MessageForm>
            }
            mainnetProvider={mainnetProvider}
            address={address}
          />
        </Route>
      </Switch>
      </Layout>


      {/* <Footer></Footer> */}
    </Layout>
  );
}

export default App;
