import React, { Component } from "react";

class Slots extends Component {
  static defaultProps = {
    fruits: ["🍒", "🍉", "🍊", "🍓", "🍇", "🥝", "🍒", "🍉", "🍊", "🍓", "🍇", "🥝"]
  };

  constructor(props) {
    super(props);
    this.state = { fruit1: "🍒", fruit2: "🍒", fruit3: "🍒", rolling: false };

    // get ref of dic onn which elements will roll
    this.slotRef = [React.createRef(), React.createRef(), React.createRef()];
    // this.roll()
    // setTimeout(() => {
    //   this.roll();
    // }, 1);
    
    
  }

  // to trigger roolling and maintain state
  roll = () => {
    this.setState({
      rolling: true
    });
    setTimeout(() => {
      this.setState({ rolling: false });
    }, 700);

    // looping through all 3 slots to start rolling
    this.slotRef.forEach((slot, i) => {
      // this will trigger rolling effect
      // const selected = this.triggerSlotRotation(slot.current);
      // const selected = this.setTop(slot.current, -600);
      // window.setInterval(() =>{
      //   this.setTop(slot.current, -600);
      // }, 1000)
      
      // window.setInterval(() =>{
      //   this.setTop(slot.current, -0);
      // }, 3000)
      // this.setState({ [`fruit${i + 1}`]: selected });
      // this.setState({ [`fruit${i + 1}`]: selected });
    });

  };
  
  // setTop = (ref, top) => {
  //   ref.style.top = `${top}px`;
  // }

  // this will create a rolling effect and return random selected option
  triggerSlotRotation = ref => {
    function setTop(top) {
      ref.style.top = `${top}px`;
    }
    
    setTop(-600)
    setTop(0);
    
    return
    
    console.log(Array.from(ref.children)[0].offsetTop)
    console.log(Array.from(ref.children).at(-1).offsetTop)
    let options = ref.children;
    let randomOption = Math.floor(
      Math.random() * Slots.defaultProps.fruits.length
    );
    let choosenOption = options[randomOption];
    setTop(-choosenOption.offsetTop + 2);
    return Slots.defaultProps.fruits[randomOption];
  };

  render() {
    return (
      <div className="SlotMachine">
        <div className="slot">
          <section>
            <div className="container" ref={this.slotRef[0]}>
              {Slots.defaultProps.fruits.map((fruit, i) => (
                <div key={i}>
                  <span>{fruit}</span>
                </div>
              ))}
            </div>
          </section>
        </div>
        <div className="slot">
          <section>
            <div className="container" ref={this.slotRef[1]}>
              {Slots.defaultProps.fruits.map(fruit => (
                <div>
                  <span>{fruit}</span>
                </div>
              ))}
            </div>
          </section>
        </div>
        <div className="slot">
          <section>
            <div className="container" ref={this.slotRef[2]}>
              {Slots.defaultProps.fruits.map(fruit => (
                <div>
                  <span>{fruit}</span>
                </div>
              ))}
            </div>
          </section>
        </div>
        <div
          className={!this.state.rolling ? "roll rolling" : "roll"}
          onClick={!this.state.rolling && this.roll}
          disabled={this.state.rolling}
        >
          {this.state.rolling ? "Rolling..." : "ROLL"}
        </div>
      </div>
    );
  }
}

export default Slots