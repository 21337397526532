import { utils } from "ethers";
import React, { Component } from "react";
import { Address } from "../components";
import ReactTooltip from 'react-tooltip';
import { Form, Input, Button, Checkbox, Typography } from 'antd';
import { ethers } from "ethers";
import { Divider, Spin, Result, Modal } from "antd";
import { Link } from "react-router-dom";
const { Title, Paragraph, Text } = Typography;
// import { IPFS } from "ipfs";
// import { makeIpfsFetch } from "js-ipfs-fetch";
import Slots from "./slot-machine";

import './MessageForm.scss';

class MessageForm extends Component {
  constructor(props) {
    super(props)
    this.state = this.initialState()
    
  }
  
  initialState() {
    return {
      calldataText: "",
      amendmentText: "",
      amendmentTextColor: "#000000",
      formStatus: "default",
      inReplyToMessageId: '',
      recipient: '',
      // recipient: '0x0000000000000000000000000000000000000000',
      amendmentGradientColor1: "#ffffff",
      amendmentGradientColor2: "#ffffff",
      amendmentGradientType: "linear",
      formStatus: "default",
      currentTextBytes: 0,
      currentTime: (new Date()),
      isFancy: true,
      gradientColorSpace: 'oklab',
      nextTokenId: 0,
      isSubmitting: false,
      success: false,
      fontSize: "16",
      gradientAngle: 45,
      modalVisible: false,
      philipImgUri: '',
      wrappedImgUri: '',
      phunkId: '',
      philipTokenJSON: '',
      wrappedTokenJSON: '',
      currentImgURI: '',
      targetImgURI: '',
      actionVerb: 'Wrap',
      mintCost: 0,
      mintAmount: 0,
      alreadyMinted: '',
      maxPerWallet: '',
      amountRemaining: '',
      totalMinted: '',
      totalMaxSupply: '',
      testSVG: '',
      testSVGId: 8348,
      walletOfOwner: [],
      svgs: [],
      newMintCount: 0,
      walletUpdateCount: 0,
      newMintTokens: 0,
      mintedIds: [],
      remainingAliens: 9,
      remainingApes: 24,
      remainingZombies: 88,
      // remainingBeanies: 44,
      // remainingZeroTraits: 8,
      remainingSevenTraits: 1,
      unmintedPunks: [],
      unmintedPunkCount: 0
    }
  }
  
  rarityClasses() {
    const rarityClasses = {"Uncommon":"uncommonbg uncommonglow","Common":"commonbg commonglow","Rare":"rarebg rareglow","Epic":"epicbg epicglow","Priceless":"pricelessbg pricelessglow","Legendary":"legendarybg legendaryglow"}
    return rarityClasses;
  }
  
  fancyCost() {
    var BigNumber = ethers.BigNumber
    
    var fixedCost = BigNumber.from(8758200 * 1e9)
    var variableCost = BigNumber.from(21600 * 1e9).mul(BigNumber.from(this.state.currentTextBytes))
    return fixedCost.add(variableCost)
  }
  
  fancyCostString() {
    var totalCost = ethers.utils.formatEther(this.fancyCost())
    
    // return `${totalCost} ETH (${(totalCost * this.props.price).toFixed(2)} USD)`
    return `$${(totalCost * this.props.price).toFixed(2)}`
  }
  
  messageBoardContract() {
    return this.props.writeContracts.ForeverMessage
  }
  
  toggleModal = async (e) => {
    e.preventDefault()
    this.setState({modalVisible: !this.state.modalVisible});
  }
  
  setTokenSVGs = async (e) => {
    
  }
  
  getWalletOfOwner = async (e) => {
    if (this.state.walletUpdateCount > this.state.newMintCount + 1 || !this.props.address) {
      return
    }
    
    console.log("wallet request")
    var idAry = (await this.props.readContracts.FashionHatPunks.walletOfOwner(this.props.address))
    
    this.setState({walletOfOwner: idAry.map(i => i.toNumber()), walletUpdateCount: this.state.walletUpdateCount + 1})
    
    idAry.forEach(async (id) => {
      if (this.state.svgs[id]) { return }
  
      var promise = this.props.readContracts.FashionHatPunks.tokenSVG(id).then(svg => {
        console.log("svg request")
        this.setState({svgs: {...this.state.svgs, [id]: svg}})
      })
      
      this.setState({svgs: {...this.state.svgs, [id]: promise}})
    })
  }
  
  oddsOfGetting(thing) {
    var remainingThings = this.state['remaining' + thing.replace(" ", '') + "s"]
    
    var raw = Math.max(this.state.mintAmount, 1) * (remainingThings / this.state.unmintedPunkCount)
    // return this.state.mintAmount * (this.state.remainingAliens / unminted.length)
    return Math.round((raw * 10000)) / 100 + "%"
  }
  
  updateVarious = async (e) => {
    this.getWalletOfOwner()
      
    var mintedIds = (await readContracts.FashionHatPunks.mintedTokenIds()).map(i => i.toNumber())
    this.setState({})
    
    var unmintedPunks = this.props.punkInfo.filter(punk => {
      return !this.state.mintedIds.includes(punk.id)
    })
    
    var unmintedPunkCount = unmintedPunks.length
        
    var remainingAliens = unmintedPunks.filter(p => p.attributes.Sex == 'Alien').length
    var remainingApes = unmintedPunks.filter(p => p.attributes.Sex == 'Ape').length
    var remainingZombies = unmintedPunks.filter(p => p.attributes.Sex == 'Zombie').length
    var remainingSevenTraits = unmintedPunks.filter(p => p.id == 8348).length
    
    this.setState({remainingAliens, remainingApes, remainingZombies,
      unmintedPunkCount, mintedIds,
      remainingSevenTraits, unmintedPunks})
  }
  
  async componentDidMount() {
    await this.updateVarious()
    
    if (this.props.readContracts) {
      // await this.getWalletOfOwner()
      
      // var mintedIds = (await readContracts.FashionHatPunks.mintedTokenIds()).map(i => i.toNumber())
      // this.setState({mintedIds})
      
      // var unmintedPunks = this.props.punkInfo.filter(punk => {
      //   return !this.state.mintedIds.includes(punk.id)
      // })
      
      // var unmintedPunkCount = unmintedPunks.length
      
      // this.setState({unmintedPunks, unmintedPunkCount})
      
      // this.unmintedDemographics()
      // var totalSupply = await this.props.readContracts.ForeverMessage.totalSupply()
      // this.setState({nextTokenId: totalSupply.toNumber() + 1})
    }
    
    var totalMinted = await this.props.readContracts.FashionHatPunks.totalSupply()
    var totalMaxSupply = await this.props.readContracts.FashionHatPunks.maxSupply()
    
    try {
      maxPerWallet = this.props.address ?
      await this.props.readContracts.FashionHatPunks.maxPerWalletForMinter(this.props.address) :
      await this.props.readContracts.FashionHatPunks.maxPerWallet()
    } catch (e) {
    }
    
    var amountRemaining = 10e3
    var mintAmount = Math.min(0, amountRemaining)
    
    var mintCost = this.props.address ? await readContracts.FashionHatPunks.totalMintCost(mintAmount, this.props.address) :
    0
    
    this.setState({amountRemaining, totalMinted, totalMaxSupply, mintAmount, mintCost})
    
    // this.intervalID = setTimeout(async () => {
    //   var alreadyMinted = this.props.address ? await readContracts.FashionHatPunks.addressToMintedTokenCount(this.props.address) : 0
    //   this.setState({alreadyMinted})
    // }, 2500)
  }
  
  // async componentDidUpdate(prevProps, prevState) {
  //   if (this.props.readContracts && this.props.readContracts.ForeverMessage) {
  //     var totalSupply = await this.props.readContracts.ForeverMessage.totalSupply()
  //     this.setState({nextTokenId: totalSupply.toNumber() + 1})
  //   }
  // }
  
  enableFancy() {
    this.setState({
      isFancy: true,
      amendmentGradientColor1: "#949494",
      amendmentGradientColor2: "#ffffff"
    })
  }
  
  // async resolveName() {
  //   var name = (await ens.getName(inputValue)).name
    
  //   if (name) {
  //     console.log(name)
      
  //     this.setState({
  //       name: name
  //     })
  //   }
  // }
  
  gradientStopArray(opts = {format: 'hex'}) {
    var c1 = this.state.amendmentGradientColor1;
    var c2 = this.state.amendmentGradientType == 'none' ? c1 : this.state.amendmentGradientColor2;
    var colorSpace = this.state.gradientColorSpace
    var steps = 10
    
    let rgb = culori.converter("rgb");
    var interpolator = culori.interpolate([c1, c2], colorSpace);
    
    var points = Array.from(Array(steps).keys()).map(i => {
      return i / (steps - 1)
    })
    
    return points.map(decimal => {
      var pct = decimal * 100
      var color = culori.formatHex(rgb(interpolator(decimal)))
      
      if (opts.format == 'integer') {
        return parseInt(color.replace(/^#/, ''), 16)
      } else if (opts.format == 'hex') {
        return color
      } else if (opts.format == 'string') {
        return `${color}`
      }
    })
  }
  
  backgroundGradient() {
    var returnedArray = this.gradientStopArray({format: 'string'})
    
    var string = returnedArray.map((color, idx) => {
      var decimal = idx / (returnedArray.length - 1)
      
      return `${color} ${decimal * 100}%`
    })
    
    if (this.state.amendmentGradientType == 'linear') {
      return `linear-gradient(${this.state.gradientAngle}deg, ${string})`
    } else {
      return `radial-gradient(at 50% 100%, ${string})`
    }
  }
  
  // async setENS() {
  //   var ensName = await this.reverse(this.props.address);
    
  //   this.setState({ensName})
  // }
  
  // async reverse(address) {       
  //   if (!address) { return }
    
  //   var lookup=address.toLowerCase().substr(2) + '.addr.reverse'
  //   var ResolverContract=await this.props.web3.eth.ens.resolver(lookup);
  //   var nh=namehash.hash(lookup);
  //   var name=await ResolverContract.methods.name(nh).call()
  //   return name;
  // }
  
  price() {
    return this.state.isFancy ? this.fancyCostString() + " + Gas" : "$0 + Gas"
  }

  // truncatedAddress(address) {
  //   return <span>{address.slice(0, 6)}...{address.slice(-4)}</span>
  // }
  
  mintFashionHatPhunk = async(e) => {
    
    const tx = this.props.tx
    var mintCost = this.state.mintCost
    var mintAmount = parseInt(this.state.mintAmount || 0)
    
    if (mintAmount == 0 || !writeContracts.FashionHatPunks) return
    
    var gasEst = await readContracts.FashionHatPunks.estimateGas.mintFashionHatPunk(this.props.address, mintAmount,
      {
        from: this.props.address,
        value: mintCost
    })
    
    gasEst = gasEst.add(gasEst.mul(5).div(100))
    
    var res = await tx(writeContracts.FashionHatPunks.mintFashionHatPunk(this.props.address, mintAmount,
      {
        value: mintCost,
        gasLimit: gasEst
    }))
    

    
    var totalMinted = await this.props.readContracts.FashionHatPunks.totalSupply()
    var totalMaxSupply = await this.props.readContracts.FashionHatPunks.maxSupply()
    
    var amountRemaining = 10e3
    
    await this.updateVarious()
    
    // await this.getWalletOfOwner()
    // this.setState({mintedIds})
    // this.unmintedDemographics()
    this.setState({amountRemaining, totalMinted,
      totalMaxSupply, mintAmount, mintCost, newMintCount: this.state.newMintCount + 1, newMintTokens: this.state.newMintTokens + mintAmount})
    // var res = await tx(writeContracts.FashionHatPunks.mintFashionHatPhunk(mintAmount))
    console.log(this.state.newMintTokens)
    
    console.log(res)
    
    mintAmount = 0
    mintCost = 0
  }
  
  loadV1Phunk = async (e) => {
    var id = this.state.phunkId
    
    var canBeWrappedByUser = false;
    var canBeUnwrappedByUser = false;
    
    // this.setState({currentImgURI})
    // return
    
    try {
      canBeWrappedByUser = await readContracts.PhunksV2Wrapper['canBeWrappedByUser(address,uint256)'](this.props.address, id)
      canBeUnwrappedByUser = await readContracts.PhunksV2Wrapper['canBeUnwrappedByUser(address,uint256)'](this.props.address, id)
    } catch(e) {
      
    }
    
    // if ((!canBeWrappedByUser && !canBeUnwrappedByUser) && process.env.NODE_ENV != 'development') {
    if ((!canBeWrappedByUser && !canBeUnwrappedByUser)) {
      alert("You aren't authorized to wrap or unwrap this phunk or your wallet is not connected")
      return
    }
    
    var svg = await readContracts.PhunksV2Wrapper.phunkImageSvg(id)
    var currentImgURI = `data:image/svg+xml;base64,${window.btoa(svg)}`;
    
    var philipImgUri = currentImgURI // (await readContracts.PhunksV2Wrapper.philipImageURI()).replace("ipfs://", "https://ipfs.io/ipfs/")
    var wrappedImgUri = currentImgURI //(await readContracts.PhunksV2Wrapper.wrapPreviewImageURI(id)).replace("ipfs://", "https://ipfs.io/ipfs/")
    
    var alreadyWrapped = canBeUnwrappedByUser
    
    var currentImgURI = currentImgURI// alreadyWrapped ? wrappedImgUri : philipImgUri
    var targetImgURI = currentImgURI//alreadyWrapped ? philipImgUri : wrappedImgUri
    
    var actionVerb = alreadyWrapped ? "Unwrap" : "Wrap"
    
    // var philipTokenJSON = await readContracts.PhunksV1Wrapper.philipTokenJSON();
    // var wrappedTokenJSON = await readContracts.PhunksV1Wrapper.wrapPreviewTokenJSON(id)
    
    // this.setState({wrappedImgUri, philipImgUri, philipTokenJSON, wrappedTokenJSON})
    // this.setState({wrappedImgUri, philipImgUri, currentImgURI, targetImgURI, actionVerb})
    this.setState({wrappedImgUri, philipImgUri, currentImgURI, targetImgURI, actionVerb})
    
    console.log(this.state)
  }
  
  filteredEvents = (e) => {
    return this.props.events.filter(e => e.args.to === this.props.address && e.args.from == "0x0000000000000000000000000000000000000000").map(i => i.args.tokenId.toNumber()).slice().reverse()
  }
  
  wrapOrUnwrap = async (e) => {
    e.preventDefault()
  
    
    const tx = this.props.tx
    var returned
    // const tx = Transactor(userSigner, gasPrice);
    
    this.setState({isSubmitting: true})
    
    if (this.state.actionVerb == "Wrap") {
      var res = await tx(writeContracts.CryptoPhunksV2['safeTransferFrom(address,address,uint256)'](
        this.props.address,
        readContracts.PhunksV2Wrapper.address,
        this.state.phunkId.toString())
      )
    } else if (this.state.actionVerb == "Unwrap") {
      var res = await tx(writeContracts.PhunksV2Wrapper.unwrap(this.state.phunkId.toString()))
    }
    

      
    console.log(res)
    
    // if (res.code) {
    //   alert('Error: ' + res.data.message)
    // }
    
    this.loadV1Phunk()
  }

  handleSubmit = async (e) => {
    e.preventDefault()
      

    
    return
    
    if (this.state.isFancy) {
      var gradientStops = this.gradientStopArray({format: 'integer'})
      
      var params = [
        this.state.amendmentText,
        parseInt(this.state.amendmentTextColor.replace(/^#/, ''), 16),
        this.state.amendmentGradientType == 'radial',
        this.state.fontSize,
        this.state.gradientAngle,
        gradientStops,
        this.state.recipient || '0x0000000000000000000000000000000000000000',
        this.state.inReplyToMessageId || 0,
        {value: this.fancyCost()}
      ]
      
      
      returned = await tx(this.messageBoardContract().createFancyMessage(...params))
    } else {
      returned = await tx(this.messageBoardContract()["createBasicMessage(string,address,uint256)"](
        this.state.amendmentText,
        this.state.recipient || '0x0000000000000000000000000000000000000000',
        this.state.inReplyToMessageId || 0
      ))
    }
    
    console.log(returned)
    
    this.setState({isSubmitting: false})
    
    if (returned) {
      var nextTokenId = this.state.nextTokenId + 1
      
      this.setState(this.initialState())
      
      this.setState({nextTokenId: nextTokenId, success: true})
      
      window.setTimeout(() => {
        this.setState({success: false})
      }, 7500)
    }
  }

  handleInputChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    var mintAmount = parseInt(this.state.mintAmount || 0)
    // console.log(mintAmount)
    
    this.setState({
      [name]: value,
      currentTextBytes: (new TextEncoder().encode(this.state.amendmentText)).length,
      success: false
    });
    
    var mintCost = this.props.address ? await readContracts.FashionHatPunks.totalMintCost(mintAmount, this.props.address) : 0
    
    this.setState({mintCost})
  }
  
  images = () => {
    return ["https://picsum.photos/200", "https://picsum.photos/100"];
  }
  
  loadSvg = async(e) => {
    e.preventDefault()
    var testSVG = await readContracts.FashionHatPunks.tokenSVG(this.state.testSVGId)
    console.log(testSVG)
    testSVG = encodeURIComponent(testSVG)
    this.setState({testSVG})
  }
  
  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        {/* <p>
        <input name="testSVGId"
            type="number" min="0" max="10000" step="1"
            onChange={this.handleInputChange}
            onInput={this.handleInputChange}
            onKeyPress={this.handleInputChange}
            onKeyUp={this.handleInputChange}
            onKeyDown={this.handleInputChange}
            onPaste={this.handleInputChange}
            onMouseDown={this.handleInputChange}
            onMouseUp={this.handleInputChange}
            value={this.state.testSVGId}
            style={{border: "1px solid #ccc", padding: "1px 10px", color: "black", background: 'rgba(255,255,255,.75)',
            border: 0, paddingRight: 0,
            width: 200, fontSize: 40}}>
            </input>
            
            <input type="submit" value="GET!" style={{fontSize: 40, padding: "1px 20px", height: "100%",
            background: 'rgba(255,255,255,.75)',
            color: 'black',
            
            }} onClick={this.loadSvg}>
            
            </input>
          <br></br>
          <img src={`data:image/svg+xml;utf8,${this.state.testSVG}`} />
        </p> */}
        {/* <Divider /> */}
        {/* <div>
      <Marquee duration={500} background="#fafafa" height="250px">
        <h1>I go weee!</h1>

        {this.images().map((image) => (
          <img src={image} alt="picsum" />
        ))}
      </Marquee>
    </div> */}
    
    {/* <Slots></Slots> */}
        <Title level={2} style={{textAlign: 'center', color: 'white', textShadow: '1px 1px 2px rgba(0,0,0,.9)'}}>
          Mint your Fashion Hat Punk today!
        </Title>
        
        <p style={{fontSize: 18}}>
          The Punk you get is determined completely pseudo-randomly via the <a style={{textDecoration: 'underline'}} href="https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle">Fisher–Yates shuffle algorithm</a>. <i>Everyone</i> has a shot at an alien, an ape, or even the 7 Trait!
        </p>
        
        <p style={{fontSize: 18}}>
          Minting costs 0.02 ETH per token for less than 10 tokens and 0.01 ETH per token for 10 or more. This means that you should NOT mint exactly 5, 6, 7, 8, or 9 tokens unless you really know what you're doing. Max 30 per transaction.
        </p>
        
        <div style={{display: 'flex', alignItems: 'center', gap: 10, flexDirection: "column"}}>
          <div style={{fontSize: 24}}>Total minted: {this.state.totalMinted.toString()} / {this.state.totalMaxSupply.toString()}</div>
          {/* <p style={{fontSize: 14}}>
            Unminted: Aliens: {this.state.remainingAliens}, Apes: {this.state.remainingApes}, Zombies: {this.state.remainingZombies}, Seven Traits: {this.state.remainingSevenTraits}
          </p> */}

          <div style={{display: 'flex', gap: 10, alignItems: "center"}}>
            <input name="mintAmount"
            type="number" min="0" max={this.state.amountRemaining} step="1"
            placeholder="Amount to mint"
            onChange={this.handleInputChange}
            onInput={this.handleInputChange}
            onKeyPress={this.handleInputChange}
            onKeyUp={this.handleInputChange}
            onKeyDown={this.handleInputChange}
            onPaste={this.handleInputChange}
            onMouseDown={this.handleInputChange}
            onMouseUp={this.handleInputChange}
            value={this.state.mintAmount}
            style={{border: "1px solid #ccc", padding: "1px 10px", color: "black", background: 'rgba(255,255,255,.75)',
            border: 0, paddingRight: 0,
            width: 'auto', fontSize: 40}}>
            </input>
            
            <input type="submit" value="Mint!" style={{fontSize: 40, padding: "1px 20px", height: "100%",
            background: 'rgba(255,255,255,.75)',
            color: 'black',
            cursor: 'pointer'
            
            }} onClick={this.mintFashionHatPhunk}>
            
            </input>
          </div>
          
          <div style={{display: 'inline-block', width: 125}}>Cost: {ethers.utils.formatEther(this.state.mintCost)} ETH</div>
          
          <p>
            Odds of minting:<br></br>
            
            {
              // ['Alien', 'Ape', 'Zombie', 'Seven Trait'].map((type, index) => {
              ['Alien', 'Ape', 'Zombie'].map((type, index) => {
                return <span key={index}>
                  {type}: {this.oddsOfGetting(type)}&nbsp; {index < 2 && " | "}
                </span>
              })
            }
          </p>
        </div>

        

        {/* <div style={{display: 'flex', gap: 20}}>
          <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
            <img src={this.state.currentImgURI} style={{width: "100%"}} />
            <div style={{textAlign: 'center'}}>
            {this.state.currentImgURI && "Your V1 Phunk Right now"}
            </div>
          </div>
          
          <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
          <img src={this.state.targetImgURI} style={{width: "100%"}} />
            <div style={{textAlign: 'center'}}>
              {this.state.currentImgURI && `Your V1 Phunk after ${this.state.actionVerb}ping!`}
            </div>
          </div>
        </div> */}

        {/* <Divider />
        <Button block onClick={this.wrapOrUnwrap} type='primary'>
          {this.state.actionVerb} it!
        </Button>
        
        <img src={this.state.wrappedImgSrc || ''} style={{width: "300px"}}></img> */}
        
        
        <Divider />
      

      
      <Title level={4} style={{color: 'white', textShadow: '1px 1px 2px rgba(0,0,0,.9)', textAlign: 'center'}}>
      {this.state.newMintCount > 0 ?
        "Behold! Your newly minted Punks!" :
        "Your latest mints will appear here!"
        }
        </Title>
        
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: "space-between", gap: 25}}>
          {
            
           this.state.walletOfOwner.slice().sort((a, b) => this.filteredEvents().indexOf(a) - this.filteredEvents().indexOf(b)).slice(0, this.state.newMintTokens).map((id, i) => {
              return <div style={{width: "48%", marginBottom: 10}}
              key={i}>
                <img data-id={id.toString()} style={{width: "100%"}} src={`data:image/svg+xml;utf8,${encodeURIComponent(this.state.svgs[id])}`}></img>
              
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10, margin: 5, fontSize: 26, justifyContent: 'center'}}>
                <span style={{color: 'white', textAlign: 'center', fontWeight: "bold", lineHeight:1, fontSize: 30}}>#{id.toString()}</span>
                <span
                  className={`${this.rarityClasses()[this.props.punkInfo[id.toString()].rarity_description]}`}
                style={{color: 'white', textAlign: 'center', lineHeight:1, padding: "10px 15px", borderRadius:5, fontWeight: 'bold'}}>{this.props.punkInfo[id.toString()].rarity_description}</span>
              </div>
                

              </div>
            })
          }
        </div>
        
        <Divider />
      

      
      <Title level={4} style={{color: 'white', textShadow: '1px 1px 2px rgba(0,0,0,.9)', textAlign: 'center'}}>
      {this.state.newMintCount.length}
        
      {this.state.walletOfOwner.length ?
        `Behold! Your ${this.state.walletOfOwner.length} Fashion Hat Punk(s)!` :
        ""
        }
        </Title>
        
          <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: "space-between", gap: 25}}>
          {
            
            this.state.walletOfOwner.map((id, i) => {
              return <div style={{width: "48%", marginBottom: 10}}
              key={i}>
                <img data-id={id.toString()} style={{width: "100%"}} src={`data:image/svg+xml;utf8,${encodeURIComponent(this.state.svgs[id])}`}></img>
              
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10, margin: 5, fontSize: 26, justifyContent: 'center'}}>
                <span style={{color: 'white', textAlign: 'center', fontWeight: "bold", lineHeight:1, fontSize: 30}}>#{id.toString()}</span>
                <span
                  className={`${this.rarityClasses()[this.props.punkInfo[id.toString()].rarity_description]}`}
                style={{color: 'white', textAlign: 'center', lineHeight:1, padding: "10px 15px", borderRadius:5, fontWeight: 'bold'}}>{this.props.punkInfo[id.toString()].rarity_description}</span>
              </div>
                

              </div>
            })
          }
        </div>
      </form>
    )
  }
}

export default MessageForm;
