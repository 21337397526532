import React, { useState, useEffect, useMemo, useRef, createRef } from "react";
import { Link } from "react-router-dom";
// import { useContractReader } from "eth-hooks";
// import { ethers } from "ethers";
import { Button, Divider, Typography, Layout, Tabs} from "antd";
import { useLookupAddress } from "eth-hooks/dapps/ens";
import { useEventListener } from "eth-hooks/events/useEventListener";
import { ethers } from "ethers";

import Message from "./Message";
import "./Message.scss";

/**
 * web3 props can be passed from '../App.jsx' into your local view component for use
 * @param {*} yourLocalBalance balance on current network
 * @param {*} readContracts contracts from current chain already pre-loaded using ethers contract module. More here https://docs.ethers.io/v5/api/contract/contract/
 * @returns react component
 */
function Home({ localProvider, readContract, loadWeb3Modal, blockExplorer, openSea,
                messageForm, mainnetProvider, address, price }) {
  
  const { Title, Paragraph, Text } = Typography;
  
  const { TabPane } = Tabs;
  
  var lookedUpAddress = useLookupAddress(mainnetProvider, address)
  
  var fancyBaseCost = ethers.BigNumber.from(8758200 * 1e9)
  var fancyBaseEther = ethers.utils.formatEther(fancyBaseCost)
  var fancyBaseUSD = (fancyBaseEther * price).toFixed(2)
  
  var fancyVariableCost = ethers.BigNumber.from(21600 * 1e9)
  var fancyVariableEther = ethers.utils.formatEther(fancyVariableCost)
  var fancyVariableUSD = (fancyVariableEther * price).toFixed(2)
  
  var tweetCost = ((fancyBaseEther * price) + (fancyVariableEther * price * 280)).toFixed(2)
  
  const events = useEventListener(readContracts, "FashionHatPunks", "Transfer", localProvider, 1);
  
  // console.log(events)
  // console.log(events.map(e => e.args.tokenId.toString()))
  
  var decimalToHex = function(d, padding = 6) {
    var hex = Number(d).toString(16);
    padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;

    while (hex.length < padding) {
      hex = "0" + hex;
    }

    return "#" + hex;
  }
  
  const foreverMessageAddress = readContracts.ForeverMessage && readContracts.ForeverMessage.address
  
  var mintedPunks = events.map((event) => {
    var url = "https://middlemarch.mypinata.cloud/ipfs/QmPbRxdyys98a9Jjn3oLygDZfnYEq8HTtyxeW1NjTwiThE/" + event.args.tokenId.toString() + ".png"
    
    return {
      imgURL: url,
      id: event.args.tokenId,
      from: event.args.from,
      to: event.args.to,
      // timestamp: event.args.sentAt,
      // senderAddress: event.args.sender,
      // messageId: parseInt(event.args.messageId),
      // fancyMessageId: parseInt(event.args.fancyMessageId),
      // recipient: event.args.recipient,
      // inReplyToMessageId: parseInt(event.args.inReplyToMessageId),
      // textColor: decimalToHex(event.args.textColor),
      // transactionHash: event.transactionHash,
      // contract: event.address,
      // gradientColors: event.args.gradientColors,
      // isRadialGradient: event.args.isRadialGradient,
      // linearGradientAngleDeg: event.args.linearGradientAngleDeg,
      // fontSize: event.args.fontSize
    }
  }).filter(e => e.to === address && e.from == "0x0000000000000000000000000000000000000000")
  
  // const [walletOfOwner, setWalletOfOwner] = useState([])
  // const [mintCount, setMintCount] = useState(0)
  const [svgs, setSvgs] = useState({});
  
  // useEffect(() => {
  //   try {
  //     console.log("wallet request")
  //     readContracts.FashionHatPunks.walletOfOwner(address).then(wallet => {
  //       setWalletOfOwner(wallet)
        
  //       walletOfOwner.forEach((id) => {
  //         if (svgs[id]) { return }
      
  //         var promise = readContracts.FashionHatPunks.tokenSVG(id).then(svg => {
  //           console.log("svg request")
  //           // var svg = 'hi'
  //           setSvgs(prevState => {
  //             return {...prevState, [id]: svg}
  //             // return Object.assign({}, prevState, { [tokenId]: val });
  //           });
            
  //           return {
  //             svg: encodeURIComponent(svg),
  //             id: id,
  //           }
  //         })
          
  //         setSvgs(prevState => {
  //           return {...prevState, [id]: promise}
  //         });
  //       })
  //     })
  //     // console.log(await readContracts.FashionHatPunks.walletOfOwner(address).map(i => i.toString()))
  //     // console.log(walletOfOwner)
      

  //   } catch (e) {
  //     console.log(e)
  //   }
  // }, [events])
  
  // useEffect(async () => {
  //   try {
  //     walletOfOwner.forEach(async (id) => {
  //       if (svgs[id]) { return }
    
  //       var promise = readContracts.FashionHatPunks.tokenSVG(id).then(svg => {
  //         console.log("svg request")
  //         // var svg = 'hi'
  //         setSvgs(prevState => {
  //           return {...prevState, [id]: svg}
  //           // return Object.assign({}, prevState, { [tokenId]: val });
  //         });
          
  //         return {
  //           svg: encodeURIComponent(svg),
  //           id: id,
  //         }
  //       })
        
  //       await setSvgs(prevState => {
  //         return {...prevState, [id]: promise}
  //       });
  //     })
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }, [walletOfOwner])
  
  // [{"id":3443},{"id":7523},{"id":6089},{"id":7804},{"id":3100},{"id":5822},{"id":5905},{"id":635},{"id":2890},{"id":8219},{"id":2460},{"id":8498},{"id":6145},{"id":1021},{"id":2491},{"id":9265},{"id":9280},{"id":5217},{"id":372},{"id":2140},{"id":2386},{"id":6915},{"id":6965},{"id":5314},{"id":2711},{"id":7191},{"id":4156},{"id":4178},{"id":4464},{"id":5577},{"id":2924},{"id":2243},{"id":5795},{"id":4472},{"id":4513},{"id":4559},{"id":4747},{"id":4830},{"id":4850},{"id":4874},{"id":5066},{"id":5234},{"id":5253},{"id":5299},{"id":5312},{"id":5336},{"id":5412},{"id":5489},{"id":5573},{"id":5742},{"id":5761},{"id":5944},{"id":6275},{"id":6297},{"id":6304},{"id":6491},{"id":6515},{"id":6586},{"id":6649},{"id":6704},{"id":6784},{"id":7014},{"id":7121},{"id":7127},{"id":7252},{"id":7337},{"id":7458},{"id":7660},{"id":7756},{"id":7914},{"id":8127},{"id":8307},{"id":8386},{"id":8472},{"id":8531},{"id":8553},{"id":8780},{"id":8857},{"id":8909},{"id":8957},{"id":9203},{"id":9368},{"id":9474},{"id":9804},{"id":9838},{"id":9909},{"id":9955},{"id":117},{"id":9997},{"id":987},{"id":1119},{"id":1190},{"id":1374},{"id":1478},{"id":1526},{"id":1658},{"id":1748},{"id":1886},{"id":1935},{"id":2066},{"id":2132},{"id":2249},{"id":2306},{"id":2329},{"id":2338},{"id":2424},{"id":2484},{"id":2560},{"id":2566},{"id":2681},{"id":2708},{"id":2938},{"id":2967},{"id":3211},{"id":3328},{"id":3393},{"id":3489},{"id":3493},{"id":3609},{"id":3636},{"id":3831}]
  
  // var mintedPunks2 = mintedPunks.map(async (punk) => {
  //   // var svg = await readContracts.FashionHatPunksRenderer.punkImageSvg(punk.id)
  //   // svg = 'hi'
  //   // setSvgs(prevState => {
  //   //   return {...prevState, [punk.id]: svg}
  //   //   // return Object.assign({}, prevState, { [tokenId]: val });
  //   // });
    
  //   if (svgs[punk.id]) { return }
    
  //   var promise = readContracts.FashionHatPunks.tokenSVG(punk.id).then(svg => {
  //     console.log("request")
  //     // var svg = 'hi'
  //     setSvgs(prevState => {
  //       return {...prevState, [punk.id]: svg}
  //       // return Object.assign({}, prevState, { [tokenId]: val });
  //     });
      
  //     return {
  //       svg: encodeURIComponent(svg),
  //       id: punk.id,
  //       from: punk.from,
  //       to: punk.to
  //     }
  //   })
    
  //   setSvgs(prevState => {
  //     return {...prevState, [punk.id]: promise}
  //   });
  // })
  
  // console.log(mintedPunks)
    
  // var xTranslate = createRef(50);
  // console.log(xTranslate)
  // var counter = 0
  // window.setInterval(() => {
  //   if (xTranslate <= 100) {
  //     xTranslate++
  //   } else {
  //     xTranslate = 0
  //   }
  // }, 1000)
  
  const { Content } = Layout
  
  return (
    <Content style={{fontSize: 16, padding: 30,
    position: 'relative', zIndex: 1, background: 'rgba(0,0,0,.75)', borderRadius: 10, marginTop: 20,
    color: 'white',
    textShadow: '1px 1px 5px rgba(0,0,0,.9)',
    }}>
      {/* <img src="/bubble.png"></img>
      {/* <h1 style={{textAlign: 'center', backgroundImage: "url(/bubble.png)", backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center', height: 200, display: "flex", alignItems: 'center', justifyContent: 'center',
      paddingBottom: 35, color: 'white'}}>Welcome to foreverMessage!</h1> */}
      
      {/* <h1>Welcome to foreverMessage</h1> */}
      <Title level={1} style={{textAlign: 'center', fontWeight:'bold', color: 'white', textShadow: '1px 1px 2px rgba(0,0,0,.9)'}}>Fashion Hat Punks</Title>
      {/* <Title level={2}>Welcome to foreverMessage</Title> */}
      {/* <Title level={4}>What would you say if you knew it would last forever?</Title> */}
      
      {/* <h2 style={{textAlign: 'center'}} >💬</h2> */}
      
      {/* <h4 style={{marginBottom: '15px'}}>
        What would you say if you knew it would last forever?
      </h4> */}
      
      <p style={{fontSize: 16, textAlign: 'center'}}>
        <a href="https://twitter.com/fashionhatpunks">Twitter</a> | <a href="https://opensea.io/collection/fashion-hat-punks">OpenSea</a> | <a href="https://etherscan.io/address/0x1febcd663f11e2654f3f02f261bee477eeff73cd">Mint Contract</a> | <a href="https://etherscan.io/address/0xf03e345bb89dc9cfaf8fda381a9e4417bfb46e7a">Data Contract</a> | <a href="https://etherscan.io/address/0x79821844625382e90abe8444fa66674e2342f631">Renderer Contract</a> 
      </p>
      
      <p style={{fontSize: 18}}>
        Fashion Hat Punks is an on-chain PFP collection that invites us to imagine a world in which all 10,000 original CryptoPunks wore fashionable hats but otherwise looked the same.
      </p>
      
      {/* <p style={{fontSize: 18}}>
        Though it might surprise you, this is the very first time ever a PFP collection has done this exact thing on-chain!
      </p> */}
      
      <p style={{fontSize: 18}}>
        Because it is stored entirely on the Ethereum blockchain, your Fashion Hat Punk's image and metadata cannot be deleted, restricted, or tampered with in any way. It will also never go out of style! However, for added peace of mind, the collection was carefully designed to not imply affiliation with any potentially-litigious rightsholder.
      </p>
      <p style={{fontSize: 18}}>
        And for added-added peace of mind, I'm going to come right out and say (though it is entirely unecessary): Fashion Hat Punks are not affiliated with Larva Labs, Yuga Labs, any fashion brand, Punk-derivative collection, or work of readymade art, including Marcel Duchamp's <i>L.H.O.O.Q.</i>
      </p>
      
      <p style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}><img style={{width: '30%'}} src="https://res.cloudinary.com/middlemarch/image/upload/v1648488279/Fashion%20Hat%20Punks/Marcel_Duchamp__1919__L.H.O.O.jpg"></img><img style={{width: '30%'}} src="https://p198.p4.n0.cdn.getcloudapp.com/items/L1uRgBbB/a5bad0b3-2beb-47bc-90cf-bad169ec61c4.png"></img>
      <span style={{width: '100%', textAlign: 'center'}}>If you think these images are affiliated that's baggage <i>you</i> are bringing to the table</span>
      </p>
      
      <p></p>
      
      {/* <p style={{fontSize: 18}}>
        Fashion Hat Punks are the very first Punk-derivative collection to not only imply no affiliation with Larva Labs, but also no affiliation of any kind with any other brand worldwide. Finally: a Punk-derivative that combines the comfort of the derivative with the freedom of the unaffiliated into one unbelievable package.
      </p>
        
      <p style={{fontSize: 18}}>Plus every wallet gets one mint free. PLUS there are some very rare Punks in here actually, some of whom agreed to be included only under the condition that they be allowed to pose in front of faux-transparent backgrounds in support of radical transparency in the fashion industry.</p>
      
      <p style={{fontSize: 18}}>One SUPER rare Punk actually became so supportive of fashion-industry transparency that he actually BECAME TRANSPARENT HIMSELF. Fortunately there's only one of him, which makes him SUPER RARE.
      </p> */}
      
      {/* <Title level={2} style={{color: 'white', textShadow: '1px 1px 2px rgba(0,0,0,.9)', textAlign: 'center'}}>Rarities!</Title>
      
      <div style={{display: 'flex', flexWrap: 'wrap', gap: 20, justifyContent: 'space-between'}}>
        <div style={{width: "47%", display: 'flex', flexDirection: 'column',
        alignItems: 'center', justifyContent: 'center', gap: 5}}>
          <img
          style={{width: '100%'}}
          src="https://middlemarch.mypinata.cloud/ipfs/QmSmZWL76TUEUiVPJs9TS47Wv5Pn4ctSr4nPeW26BB9oeh/24.png"></img>
          <Title level={3} style={{color: 'white', textShadow: '1px 1px 2px rgba(0,0,0,.9)'}}>Rare!</Title>
        </div>
        
        <div style={{width: "47%", display: 'flex', flexDirection: 'column',
        alignItems: 'center', justifyContent: 'center', gap: 5}}>
          <img
          style={{width: '100%'}}
          src="https://middlemarch.mypinata.cloud/ipfs/QmSmZWL76TUEUiVPJs9TS47Wv5Pn4ctSr4nPeW26BB9oeh/72.png"></img>
          <Title level={3} style={{color: 'white', textShadow: '1px 1px 2px rgba(0,0,0,.9)'}}>Epic Rare!</Title>
        </div>
        
        <div style={{width: "47%", display: 'flex', flexDirection: 'column',
        alignItems: 'center', justifyContent: 'center', gap: 5}}>
          <img
          style={{width: '100%'}}
          src="https://middlemarch.mypinata.cloud/ipfs/QmSmZWL76TUEUiVPJs9TS47Wv5Pn4ctSr4nPeW26BB9oeh/187.png"></img>
          <Title level={3} style={{color: 'white', textShadow: '1px 1px 2px rgba(0,0,0,.9)'}}>Legendary Rare!</Title>
        </div>
        
        <div style={{width: "47%", display: 'flex', flexDirection: 'column',
        alignItems: 'center', justifyContent: 'center', gap: 5}}>
          <img
          style={{width: '100%'}}
          src="https://middlemarch.mypinata.cloud/ipfs/QmSmZWL76TUEUiVPJs9TS47Wv5Pn4ctSr4nPeW26BB9oeh/27.png"></img>
          <Title level={3} style={{color: 'white', textShadow: '1px 1px 2px rgba(0,0,0,.9)'}}>
          Fuggedaboutit!
            </Title>
        </div>
      </div> */}

      
      
      {/* <p>
        <a href="https://opensea.io/collection/philipinternproject">Original V1 Punks (OpenSea)</a>
        { " | " }
        <a href="https://opensea.io/collection/official-v1-punks">Wrapped V1 Punks (OpenSea)</a>
        { " | " }
        <a href="https://etherscan.io/address/0x235d49774139c218034c0571ba8f717773edd923#code">Verified Contract</a>
      </p> */}
      
      {/* <p>
        No more Philip
      </p> */}
      
      {readContracts && readContracts.FashionHatPunks && messageForm}
      
      {/* <div style={{width: 960, display: 'flex'}}>
        <img style={{width: '50%'}} src="local_pngs/1.png"></img>
        <img style={{width: '50%'}} src="local_svgs/1.svg"></img>
      </div> */}

      

      
      {/* <div style={{width: 240, height: 240, display: 'flex', overflow: 'hidden', position: "relative", zIndex: 2}}>
        <div style={{display: 'flex', position: 'relative', transition: '.1s all',
        transform: `translateX(${xTranslate}%)`}}>
        {
        Object.keys(idMapping).slice(0, 20).map(id => {
          return <img data-id={id} style={{display: 'block', width: 240, height: 240}} src={`https://middlemarch.mypinata.cloud/ipfs/QmY5asrVsstptK1Tjij6DZnoe5atz2CG3Zte5eNzDc7dtk/${idMapping[id]}.png`}></img>
        })
      }
        </div>

      </div> */}
      
      {/* <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: "space-between", gap: 25}}>
        {
          walletOfOwner.map((id, i) => {
            // console.log(event)
            return <div style={{width: "48%", marginBottom: 10}}
            key={i}>
              <img data-id={id.toString()} style={{width: "100%"}} src={`data:image/svg+xml;utf8,${encodeURIComponent(svgs[id])}`}></img>
            
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10, margin: 5, fontSize: 26, justifyContent: 'center'}}>
              <span style={{color: 'white', textAlign: 'center', fontWeight: "bold", lineHeight:1, fontSize: 30}}>#{id.toString()}</span>
              <span
                className={`${rarityClasses[rarityStrings[id.toString()]]}`}
              style={{color: 'white', textAlign: 'center', lineHeight:1, padding: "10px 15px", borderRadius:5, fontWeight: 'bold'}}>{rarityStrings[id]}</span>
            </div>
              

            </div>
          })
        }
      </div> */}

      
      
    </Content>
  );
}

export default Home;
